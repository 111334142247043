import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { TomiStackContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";

export const WidthrawRewards =()=>{
    const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.StackContract;
    const contract = TomiStackContract(contractAddress,web3);
    const RewardWidraw = useCallback (
        async(index) =>{
            try {
                if(index){
                const balance = await contract.methods.unStakeWithAPY(index).send({from:account})
                return balance;
                // console.log('uuuuu', balance);
                }
            }
             catch (error) {
                console.log('9999999', error);
                throw error;
            }
        },[contract]
    );
    return { RewardWidraw: RewardWidraw };
}
export default WidthrawRewards;
