import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./footernew.scss";
// import { Link } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

const Footernew = () => {
  return (
    <>
      <section className="main-footer ">
        <div className="container-fluid paddrightzero">
          <div className="row">
            <div className="col-xl-12 col-12 m-auto p-0">
              <div className="row">
                <div className="col-xl-12 col-12 p-0 m-auto">
                  <div className="row">
                    <div className="col-xl-3   col-sm-12 padd-sm p-0 ">
                      <div className="info">
                        <Link to="/">
                          <img
                            className="logoss"
                            src="\assets\tomilogo.svg"
                          ></img>
                        </Link>
                        {/* <p>
                          Fresh and convenient: Come for Groceries,
                          <br /> Stay for Experience!
                        </p> */}
                      </div>
                    </div>
                    <div className="col-xl-2   col-sm-6 col-6 p-0 ">
                      <div className="line ">
                        <h4>Solutions</h4>
                        <div className="list">
                          <a href="https://tomi.com/browser" target="_blank">
                            <p>Browser</p>
                          </a>

                          <a href="https://tdns.network/" target="_blank">
                            <p>Domain</p>
                          </a>

                          {/* <p>Help Center</p> */}

                          <a href="https://tomi.com/wallet" target="_blank">
                            <p>Wallet</p>
                          </a>

                          <a href="https://dao.tomi.com/" target="_blank">
                            <p>DAO</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2  col-sm-6 col-6 p-0">
                      <div className="line">
                        <h4>Community</h4>
                        <div className="list">
                          <a
                            href="https://twitter.com/tomipioneers"
                            target="_blank"
                          >
                            <p>X</p>
                          </a>

                          <a href="https://t.me/tomipioneers" target="_blank">
                            {" "}
                            <p>TG announcement </p>
                          </a>
                          <a
                            class="scoialimg"
                            href="https://t.me/tomi_official_chat"
                            target="_blank"
                          >
                            {" "}
                            <p>TG group</p>
                          </a>

                          <a
                            href="https://discord.gg/qUVaNypBRe"
                            target="_blank"
                          >
                            <p>Discord</p>
                          </a>

                          <a
                            href="https://www.reddit.com/r/tomipioneers/?rdt=51853"
                            target="_blank"
                          >
                            {" "}
                            <p>Reddit</p>
                          </a>
                          <a href="https://tomiarmy.com/" target="_blank">
                            <p>tomiArmy</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2   col-sm-6  col-6 p-0">
                      <div className="line">
                        <h4>Education</h4>
                        <div className="list">
                        <a href="/news" target="_blank">

                     
                            <p>blog</p>
                             
                        </a>

                          <a
                            href="https://medium.com/tomipioneers"
                            target="_blank"
                          >
                            {" "}
                            <p>medium</p>
                          </a>
                          <a
                            href="https://www.youtube.com/@tomipioneers"
                            target="_blank"
                          >
                            {" "}
                            <p>youtube</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3  col-sm-12  p-0">
                      <div className="gradientborder">
                        <div className="linelast">
                          <div className="listsss">
                            <p>
                              <a href="mailto:tomiteam@proton.me">
                                <img
                                  className="my-2"
                                  src="\assets\pinkemail.svg"
                                />
                              </a>
                            </p>
                            <h4 className="leeefftter">Email Us</h4>
                            <p className="lefttlastpara">
                              Send your questions or suggestions securely via
                              our encrypted email for prompt support and
                              feedback.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-11 col-12 p-0  m-auto">
                  <hr className="toplineonly"></hr>
                  <div className="lastdddsdfcc">
                    <p className="lastbutmm">
                      © 2024 tomi, all rights reserved.
                    </p>
                    <Link to="/privacy">
                      <p className="lastbutmm">Terms of use | Privacy policy</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footernew;
