import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { TomiStackContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";

export const GetPendingReward = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.StackContract;
    const contract = TomiStackContract(contractAddress, web3);
    const PendingReward = useCallback(
        async (index) => {
            // console.log("pendingrewardget",index)
            try {
                if (account && index) {
                    // console.log("pendingrewardget",index)
                    const balance = await contract.methods.calculateRewards(index, account).call()
                    const bal = web3.utils.fromWei(balance, "ether");
                    // console.log("pending reward we get", bal)
                    return bal;
                }
            }
            catch (error) {
                console.log('9999999', error);
                throw error;
            }
        }, [contract]
    );
    return { PendingReward: PendingReward };
}
export default GetPendingReward;
