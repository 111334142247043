import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getTomiContract,getuniSwapRouter } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import axios from "axios";
import { API_URLTomiPrice } from '../../utils/ApiUrl';

export const GetTotalHolders = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.TomiContract;
  const contract = getTomiContract(tokenAddress, web3);
  const tokenAddress1 = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
  const contract1 = getuniSwapRouter(tokenAddress1, web3);
  const GetHolder = useCallback(async (account) => {
    const approved = await contract.methods.balanceOf(Environment.StackContract).call();
    let a= {}
    // let balance = web3.utils.fromWei(approved, "ether")
    let balance = parseInt(approved) / 1000000000000000000
    a.tomi= balance
    const ethVAl = web3.utils.toWei('1', 'ether')
    const path = [Environment.TomiContract, '0xdac17f958d2ee523a2206206994597c13d831ec7']
    const amounts = await contract1.methods.getAmountsOut(ethVAl, path).call();
    const outputAmount = web3.utils.fromWei(amounts[1], 'wei');
    const price = outputAmount / Math.pow(10, 6);
    // console.log("balance",price,balance)
    let c = (balance * price)
    a.dollar=c
    // console.log("balance", a)
    return a;
  }, [contract]);

  return { GetHolder: GetHolder };
};

export default GetTotalHolders;